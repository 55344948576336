module.exports = [{
      plugin: require('C:/Users/maste/source/repos/JacobAshcraftStatic/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"C:\\Users\\maste\\source\\repos\\JacobAshcraftStatic\\src\\templates\\page.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":590,"linkImagesToOriginal":false,"withWebp":true}},{"resolve":"gatsby-remark-prismjs"},{"resolve":"gatsby-remark-responsive-iframe"},{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-autolink-headers"}]},
    },{
      plugin: require('C:/Users/maste/source/repos/JacobAshcraftStatic/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/maste/source/repos/JacobAshcraftStatic/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/maste/source/repos/JacobAshcraftStatic/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-143944390-1"},
    },{
      plugin: require('C:/Users/maste/source/repos/JacobAshcraftStatic/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
