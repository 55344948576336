/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("./content/css/bootstrap.min.css")
require("./content/css/fa-all.min.css")
require("./content/css/custom.css")
// require("./content/css/plyr.css")